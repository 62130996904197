import { gamePeriodEnum } from "enum/statistics/useTableEnum";
import { format } from "date-fns";

export const getPreviousDateByPeriodEnum = (period) => {
  const currentDate = new Date();
  const previousDate = new Date(currentDate);

  switch (period) {
    case gamePeriodEnum.WEEK.enum:
      previousDate.setDate(previousDate.getDate() - 7); // 7일 전
      break;
    case gamePeriodEnum.MONTH.enum:
      previousDate.setMonth(previousDate.getMonth() - 1); // 1달 전
      break;
    case gamePeriodEnum.THREE_MONTH.enum:
      previousDate.setMonth(previousDate.getMonth() - 3); // 3달 전
      break;
    case gamePeriodEnum.SIX_MONTH.enum:
      previousDate.setMonth(previousDate.getMonth() - 6); // 6달 전
      break;
    case gamePeriodEnum.YEAR.enum:
      previousDate.setFullYear(previousDate.getFullYear() - 1); // 1년 전
      break;
    default:
      break;
  }

  // 현재 날짜를 yyyy-MM-dd 형태로 변환
  const formattedCurrentDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

  // 이전 날짜를 yyyy-MM-dd 형태로 변환
  const formattedPreviousDate = `${previousDate.getFullYear()}-${(previousDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${previousDate.getDate().toString().padStart(2, "0")}`;

  return {
    endDate: formattedCurrentDate,
    startDate: formattedPreviousDate,
  };
};

export const getFormatStringToDate = (dateString) => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  // 날짜를 원하는 형식으로 포맷팅하기 (시간 제외)
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}`;
};

/**
 * 날짜를 yyyy-MM-dd HH:mm:ss 형태로 변환
 */
export const getFormatDatetime = (param) => {
  if (!param) {
    return null;
  }

  return param ? format(new Date(param), "yyyy-MM-dd HH:mm:ss") : "-";
};


export const getFormatDateMin = (param) => {
  if (!param) {
    return null;
  }

  return param ? format(new Date(param), "yyyy-MM-dd HH:mm") : "-";
};

/**
 * 오늘 날짜 반환
 * @param {boolean} time 
 * @returns {String}
 */
export const getToday = (time = false) => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0]; // yyyy-mm-dd 형식
  if (time) {
    const formattedTime = today.toTimeString().split(' ')[0]; // hh:mm:ss 형식
    return `${formattedDate} ${formattedTime}`;
  }
  return formattedDate;
}

/**
 * Date type -> String date
 * ex ) Sat Aug 05 2023 00:00:00 GMT+0900 (한국 표준시) -> 2023. 08. 05
 * @param {
 * } date
 * @returns
 */
export const dateFormat = (date) => {
  return date ? format(new Date(date), "yyyy-MM-dd") : "-";
};

export const timeFormat = (date) => {
  return date ? format(new Date(date), "HH:mm:ss") : "-";
};

/**
 * yyyymm -> yyyy년 mm월
 * @param {String} dateString
 * @returns
 */
export const yyyymmToDateFormat = (dateString) => {
  if (!dateString) {
    return null;
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);

  // 날짜를 "yyyy년 mm월" 형식의 문자열로 변환
  return `${year}년 ${month}월`;
};

/**
 * yyyymmdd -> yyyy-mm
 * @param {String} dateString
 * @returns
 */
export const yyyymmddToYearMonth = (dateString) => {
  if (!dateString) {
    return null;
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);

  // 날짜를 "yyyy-mm" 형식의 문자열로 변환
  return `${year}-${month}`;
};


/**
 * yyyymmdd -> yyyy-mm-dd
 * @param {String} dateString
 * @returns
 */
export const yyyymmddToDateFormat = (dateString) => {
  if (!dateString) {
    return null;
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  // 날짜를 "yyyy-mm-dd" 형식의 문자열로 변환
  return `${year}-${month}-${day}`;
};
/**
 * date to yyyymmdd format
 * ex ) 2024-05-31T06:00:00.000Z ->  20240531
 * @param {date} dateString
 * @returns
 */
export const formatDateToYYYYMMDD = (dateString) => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}${month}${day}`;
};

/**
 * 숫자를 표시할 때
 * 3글자마다 쉼표(,)를 추가
 * @param {*} number
 * @returns
 */
export const formatPrice = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * 지정된 maxLength를 초과하는 경우 문자열을 자르고 끝에 "..."을 추가합니다.
 * @param {string} str - 자를 문자열입니다.
 * @param {number} maxLength - 자른 문자열의 최대 길이입니다.
 * @returns {string} - 자른 문자열입니다.
 */
export const truncateString = (str, maxLength) => {
  if (!maxLength) {
    return str;
  }
  if (!str) {
    return "";
  }
  if (str?.length > maxLength) {
    return str?.substring(0, maxLength) + "...";
  }
  return str;
};
