import { useEffect } from "react";

import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import { TextareaForm } from "components/common/CommonForm";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import { dbUser, dbUserState2 } from "enum/db/useUserEnum";
import { t } from "i18next";
import api from "interceptor/api";
import { Column, OptionBox, Row, SpaceBox, SubTitleBox } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { BlockBtn } from "components/common/TableStatus";
import { LineSection } from "style/useLayoutStyles";
import { AreaTitle, TextBox } from "style/useTextStyles";
import { getFormatDatetime } from "utils/date/DateUtil";
import UserCommission from "./UserCommission";
import UserInfoForm from "./UserInfoForm";
import UserInfoTab from "./UserInfoTab";
import UserModifyHistory from "./UserModifyHistory";

const UserInfo = ({ userNo, getTableItem, tabNm = 1 }) => {
  const useStated = useStateData();
  const { setLoading, isAdmin } = useCommonState();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();
  const { closePopup } = usePopup();

  useEffect(() => {
    useStated.setErrorData([], true);

    getUserInfo();
  }, []);

  const showApproveJoinRequestAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("user.join_approve_complete") : t("user.join_approve_fail"),
      message: isSuccess ? t("user.join_approve_complete_msg") : t("user.join_approve_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };
  const showRejectJoinRequestAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("user.join_reject_complete") : t("user.join_reject_fail"),
      message: isSuccess ? t("user.join_reject_complete_msg") : t("user.join_reject_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  /**
   * 가입 완료처리
   */
  const showApproveJoinRequestConfirm = (userNo, nickName) => {
    openConfirm({
      title: t("user.join_approve"),
      message: t("user.join_approve_confirm", { nickName: nickName || "-", userNo: userNo || "-" }),
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => approveJoinRequest([userNo]),
    });
  };
  const approveJoinRequest = (checkedUserNo) => {
    setLoading(true);
    const apiUrl = `/v1/user/join-request/approve`;
    api
      .put(apiUrl, {
        user_arr: checkedUserNo,
      })
      .then((res) => {
        showApproveJoinRequestAlert(true);
        refreshUser();
      })
      .catch((err) => {
        showApproveJoinRequestAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * 가입 불가처리
   */
  const showRejectJoinRequestConfirm = (userNo, nickName) => {
    openConfirm({
      title: t("user.join_reject"),
      message: t("user.join_reject_confirm", { nickName: nickName || "-", userNo: userNo || "-" }),
      iconURL: true,
      mainBtn: t("common.check"),
      Func: () => rejectJoinRequest([userNo]),
    });
  };
  const rejectJoinRequest = (checkedUserNo) => {
    setLoading(true);
    const apiUrl = `/v1/user/join-request/reject`;
    api
      .put(apiUrl, {
        user_arr: checkedUserNo,
      })
      .then((res) => {
        showRejectJoinRequestAlert(true);
        refreshUser();
        closePopup();
      })
      .catch((err) => {
        showRejectJoinRequestAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showCommissionPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? `${t("userInfo.comp_info")}(${nickName})` : t("userInfo.comp_info"),
      content: UserCommission,
      props: {
        userNo,
        refreshUser,
      },
    });
  };

  const showModifyPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? `${t("userInfo.modify_user")}(${nickName})` : t("userInfo.modify_user"),
      content: UserInfoForm,
      props: {
        userNo,
        refreshUser,
      },
    });
  };

  const showModifyHistoryPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? t("userInfo.modify_user_history_title", { nickName: nickName }) : t("userInfo.modify_user_history"),
      content: UserModifyHistory,
      props: {
        userNo,
      },
    });
  };

  const getUserInfo = () => {
    setLoading(true);

    const apiUrl = `/v1/user/all/info/${userNo}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue(res.data.content);
        useForm.setFormValue({
          ...res.data.content,

          state2: JSON.parse(res.data.content.state2_arr || "[]"),
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const refreshUser = () => {
    getTableItem();
    getUserInfo();
  };

  const useForm = useFormData({});

  const accountInOutTotal = (useForm.formValue.account_in_money || 0) - (useForm.formValue.account_out_money || 0);
  const accountInOutClass = accountInOutTotal > 0 ? "complete" : accountInOutTotal < 0 ? "warn" : "";

  const betMinusWinTotal = (useForm.formValue.bet_money || 0) - (useForm.formValue.win_money || 0);
  const betMinusWinClass = betMinusWinTotal > 0 ? "complete" : betMinusWinTotal < 0 ? "warn" : "";

  return (
    <>
      <Row $gap="28px" $overX="auto">
        <Column $gap="48px" $width="700px">
          <Column className="area-box">
            <SubTitleBox>
              <Row $gap="6px" $align="center">
                <AreaTitle>{t("user.user_info")}</AreaTitle>
              </Row>
            </SubTitleBox>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_state1")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {dbUser.state1[useForm.formValue?.state1]?.label || "-"}
                </TextBox>
                {useForm.formValue.state1 &&
                  useForm.formValue.state1 === dbUser.state1.W.value &&
                  (isAdmin ? (
                    <Row $gap="6px">
                      <MainBtn
                        $width="45px"
                        $pad="0px 8px"
                        onClick={() => showApproveJoinRequestConfirm(userNo, useForm.formValue.nickname)}
                      >
                        {t("common.approve")}
                      </MainBtn>
                      <WhiteBtn
                        $width="45px"
                        $pad="0px 8px"
                        onClick={() => showRejectJoinRequestConfirm(userNo, useForm.formValue.nickname)}
                      >
                        {t("common.reject")}
                      </WhiteBtn>
                    </Row>
                  ) : null)}
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {`${useForm.formValue.nickname || "-"} (${useForm.formValue.no || "-"})`}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_state2")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(() => {
                    return useForm.formValue.state2
                      ? useForm.formValue.state2.map((state) => dbUserState2.state2[state]?.label || "-").join(", ")
                      : "-";
                  })()}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("common.phone")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.phone_number || "-"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_id")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.id || "-"}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.refer_code")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.refer_code || "-"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.money_balance")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(useForm.formValue.money?.toLocaleString() || "0") + "(C)"}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.point_balance")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(useForm.formValue.point?.toLocaleString() || "-") + "(P)"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              {useForm.formValue.state1 && useForm.formValue.state1 === dbUser.state1.W.value ? (
                <OptionBox $flex="1" $align="center">
                  <TextBox $width="130px" $line="var(--l-sub)">
                    {t("user.join_request_at")}
                  </TextBox>
                  <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                    {getFormatDatetime(useForm.formValue.join_request_at) || "-"}
                  </TextBox>
                </OptionBox>
              ) : (
                <OptionBox $flex="1" $align="center">
                  <TextBox $width="130px" $line="var(--l-sub)">
                    {t("user.user_lv")}
                  </TextBox>
                  <img
                    src={useForm.formValue.img_uuid ? process.env.REACT_APP_IMG_URL + useForm.formValue.img_uuid : ""}
                    alt=""
                    style={{ width: "20px", height: "20px" }}
                  />
                  <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                    {`${useForm.formValue.level_name || "-"} (${useForm.formValue.lv !== undefined && useForm.formValue.lv !== null ? useForm.formValue.lv : "-"})`}
                  </TextBox>
                </OptionBox>
              )}
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.join_confirm_at")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {getFormatDatetime(useForm.formValue.join_confirm_at) || "-"}
                </TextBox>
              </OptionBox>
            </Row>
            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.ip_block")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  <MainBtn $width="60px" $minHeight="20px" $backColor={useForm.formValue.ip_block_yn === 'N' && "var(--c-gray-600)"}>
                    {useForm.formValue.ip_block_yn === 'Y' ? 'ON' : 'OFF'}
                  </MainBtn>
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.join_ip")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.join_ip}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <SpaceBox $flex="1" $align="center"></SpaceBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.account_bank_name")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.account_bank_name || "-"}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_agent_code")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.code || "-"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.account_name")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.account_name || "-"}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_agent_type_name")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.type_name || "-"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.account_number")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.account_no || "-"}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.join_code")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.join_code || "-"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <SpaceBox $flex="1" $align="center"></SpaceBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_total_deposit")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(useForm.formValue.account_in_money?.toLocaleString() || "0") + "(C)"}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_total_bet_money")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(useForm.formValue.bet_money?.toLocaleString() || "0") + "(C)"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_total_withdrawal")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(useForm.formValue.account_out_money?.toLocaleString() || "0") + "(C)"}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.user_total_win_money")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(useForm.formValue.win_money?.toLocaleString() || "0") + "(C)"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.total_deposits_withdrawals")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)" className={accountInOutClass}>
                  {accountInOutTotal.toLocaleString(undefined, {
                    signDisplay: "exceptZero",
                  }) + "(C)"}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.total_bets_wins")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)" className={betMinusWinClass}>
                  {betMinusWinTotal.toLocaleString(undefined, {
                    signDisplay: "exceptZero",
                  }) + "(C)"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <SpaceBox $flex="1" $align="center"></SpaceBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.first_charge_point")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.first_charge_point_yn &&
                    useForm.formValue.first_charge_point_yn === dbUser.every_charge_point_yn.Y.value ? (
                    <MainBtn $width="60px" $minHeight="20px">ON</MainBtn>
                  ) : (
                    <WhiteBtn $width="60px" $minHeight="20px">OFF</WhiteBtn>
                  )}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.total_points")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(useForm.formValue.accumulated_point?.toLocaleString() || "0") + "(P)"}
                </TextBox>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.every_charge_point")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.every_charge_point_yn &&
                    useForm.formValue.every_charge_point_yn === dbUser.every_charge_point_yn.Y.value ? (
                    <MainBtn $width="60px" $minHeight="20px">ON</MainBtn>
                  ) : (
                    <WhiteBtn $width="60px" $minHeight="20px">OFF</WhiteBtn>
                  )}
                </TextBox>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $flex="1" $align="center">
                <TextBox $width="130px" $line="var(--l-sub)">
                  {t("user.change_point")}
                </TextBox>
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {(useForm.formValue.change_point?.toLocaleString() || "0") + "(P)"}
                </TextBox>
              </OptionBox>
            </Row>

            {isAdmin ? (
              <Row $align="center" $gap="8px" $pad="6px 0px">
                <Row $width="550px">
                  <TextBox $width="130px" $line="var(--l-sub)">
                    {t("user.admin_memo")}
                  </TextBox>
                  <TextareaForm type="text" name="memo" disabled="ture" {...useForm} {...useStated} />
                </Row>
                <Column $gap="8px">
                  <Row $gap="6px">
                    <WhiteBtn $pad="6px 12px" onClick={() => showModifyPopup(userNo, useForm.formValue.nickname)}>
                      {t("common.modify")}
                    </WhiteBtn>
                    <MainBtn $pad="6px 12px" onClick={() => showModifyHistoryPopup(userNo, useForm.formValue.nickname)}>
                      {t("user.modify_history")}
                    </MainBtn>
                  </Row>
                  <MainBtn $pad="6px 12px" onClick={() => showCommissionPopup(userNo, useForm.formValue.nickname)}>
                    {t("userInfo.comp_info")}
                  </MainBtn>
                </Column>
              </Row>
            ) : (
              <Row $jus="end" $align="center" $gap="8px" $pad="24px 0px">
                <Row $gap="6px">
                  <MainBtn $pad="6px 12px" onClick={() => showCommissionPopup(userNo, useForm.formValue.nickname)}>{t("userInfo.view_comp")}</MainBtn>
                </Row>
              </Row>
            )}
          </Column>
        </Column>

        <Column $width="1000px">
          <UserInfoTab userNo={userNo} tabNm={tabNm}></UserInfoTab>
        </Column>
      </Row>
    </>
  );
};

export default UserInfo;
