import api from "interceptor/api";
import React, { useEffect, useState } from "react";
import { Column, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { EachCheckForm, InputForm, SelectForm } from "./CommonForm";
import { agentFilterCondition } from "enum/agent/userAgentUserEnum";
import { checkboxYNEnum } from "enum/useCommonEnum";
import { t } from "i18next";

const AgentSearchFilter = ({ useSearch, useStated, $mar }) => {

  const [agentTypeList, setAgentTypeList] = useState([]);
  const [agentNameList, setAgentNameList] = useState([]);
  const [allAgentNameList, setAllAgentNameList] = useState([]);
  
  const searchSubAgentYn = useSearch.formValue?.search_sub_agent_yn;
  
  const selectAgentType = (type) => {
    if (type) {
      setAgentNameList(
        allAgentNameList.filter((item) => {
          return item.type === type
        })
      )
    } else {
      setAgentNameList(allAgentNameList)
    }
  }
  
  // 에이전트 타입
  const getAgentTypeList = () => {
    api.get("/v1/agent/info/agent-types")
    .then((res) => {
      const agentType = res.data.content.reduce((acc, item) => {
        acc.push({
          label: item.name,
          value: item.type,
          key: item.depth
        });
        return acc;
      }, [{
        label: t("common.all"),
        value: "",
        key: 0,
      }]);

      setAgentTypeList(agentType)      
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        useStated.setErrorData(err.response.data.errors, true);
      }
    });
  };

  // 에이전트 정보
  const getAgentInfoList = () => {
    api.get("/v1/agent/info/list")
      .then((res) => {
      const agentName = res.data.content.reduce((acc, item) => {
        acc.push({
          label: item.name,
          value: item.name,
          type: item.type,
          key: item.no
        });
        return acc;
      }, [{
        label: t("common.all"),
        value: "",
        key: 0,
      }]);

      setAgentNameList(agentName)      
      setAllAgentNameList(agentName)      
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        useStated.setErrorData(err.response.data.errors, true);
      }
    });
  };
  
  useEffect(() => {
    getAgentTypeList();
    getAgentInfoList();
  }, []);
  
  return (
    <Row $mar={$mar || ""} $gap="12px">
      {searchSubAgentYn !== undefined && (
        <Column $gap="4px">
          <FilterTitle>{t("agent.search_agent")}</FilterTitle>
          <Row $gap="4px" $align="center">
            <SelectForm
              name="search_agent_category"
              placeholder={t("common.select")}
              options={agentFilterCondition}
              {...useSearch}
              {...useStated}
            />
            <InputForm type="text" name="search_agent_val" placeholder={t("common.input")} {...useSearch} {...useStated} />
            <EachCheckForm
              $size="var(--s-caption)"
              name="search_sub_agent_yn"
              labelFor="search_sub_agent_yn"
              options={checkboxYNEnum}
              labelName={t("agent.include_sub_agent")}
              {...useSearch}
            />
          </Row>
        </Column>
      )}
      <Column $gap="4px">
        <FilterTitle>{t("agent.type")}</FilterTitle>
        <Row $gap="4px">
          {agentTypeList && (
            <SelectForm
              name="search_agent_type"
              placeholder={t("common.all")}
              options={agentTypeList}
              {...useSearch}
              {...useStated}
              onChangeEx={(e, v) => {selectAgentType(v)}}
            />
          )}
        </Row>
      </Column>
      <Column $gap="4px">
        <FilterTitle>{t("agent.name")}</FilterTitle>
        <Row $gap="4px">
          {agentNameList && (
            <SelectForm
              name="search_agent_name"
              placeholder={t("common.all")}
              options={agentNameList}
              {...useSearch}
              {...useStated}
            />
          )}
        </Row>
      </Column>
    </Row>
  );
};

export default AgentSearchFilter;
