import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import { RadioForm } from "components/common/CommonForm";
import { useCommonState } from "contexts/useCommonState";
import React, { useState } from "react";
import { AreaHeader, Column, Row } from "style/custom/useCustomLayout";
import { FlexBox, IconLink, LineSection, HoverCon, HoverBox } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import styled from "styled-components";
import api from "interceptor/api";
import { MainBtn, MenuToggleBtn, RedBtn } from "style/useButtonStyles";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import { t } from "i18next";

const SubTitleBox = styled(FlexBox).attrs({ className: "subtitle-box" })`
  padding: 10px 24px;
  background-color: var(--c-tableHead);
  align-items: center;
  justify-content: space-between;
`;

const OptionBox = styled(FlexBox)`
  gap: 16px;
  padding: 14px 20px;
  min-height: 62px;
  border-bottom: 1px solid var(--c-gray-300);
`;

const MenuCategory = () => {
  const useTG = useTableData(true);
  const useStated = useStateData();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();
  const { setLoading } = useCommonState();
  const [dropdownState, setDropdownState] = useState({});

  const showSuccessAlert = () => {
    openAlert({
      title: t("common.save_msg"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showFailAlert = () => {
    openAlert({
      title: t("common.save_fail"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showHistoyDel = () => {
    openConfirm({
      title: t("menuCategory.manual_del_bet_histoy"),
      message: t("menuCategory.del_bet_histoy_msg"),
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => historyBetDel(),
    });
  };

  const historyBetDel = () => {
    api.delete(`/v1/game/history-bet`)
    .then(() => {
      showSuccessAlert();
    })
    .catch((err) => {
      showFailAlert()
      console.error(err);
    });
  }
  
  const searchStateEnum = {
    all: {
      label: t("days.one_month"),
      value: "YNN",
      key: 0,
    },
    registed: {
      label: t("days.three_month"),
      value: "NYN",
      key: 1,
    },
    unregisted: {
      label: t("days.six_month"),
      value: "NNY",
      key: 2,
    },
  };

  const handleDropdownToggle = (state) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [state]: !prevState[state],
    }));
  };

  const openAllDropdowns = () => {
    setDropdownState({
      1: true
    });
  };

  const closeAllDropdowns = () => {
    setDropdownState({
      1: false
    });
  };

  const getSiteDelBet = () => {
    setLoading(true);
    const apiUrl = `/v1/site/config/del-period-bet/1`;
    api.get(apiUrl)
    .then((res) => {
      useSearch.setFormValue(...res.data.content)
    })
    .catch((err) => {
      console.error(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const UpdateSiteDelBet = () => {
    setLoading(true);
    const apiUrl = `/v1/site/config/del-period-bet/1`;
    api.put(apiUrl, {
      ...useSearch.formValue
    })
    .then(() => {
      showSuccessAlert()
    })
    .catch((err) => {
      console.error(err);
      showFailAlert();
    }).finally(() => {
      setLoading(false);
    });
  };

  const useSearch = useFormData({}, getSiteDelBet, useTG);

  return (
    <>
      <AreaHeader>
        <SubText>{t("menuCategory.site_settings")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menuCategory.manage_data")}</AreaTitle>
        <Row $gap="8px" style={{ marginLeft: "auto" }}>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            $line="20px"
            onClick={openAllDropdowns}
          >
            {t("common.unfold_all_menu")}
          </MenuToggleBtn>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            onClick={closeAllDropdowns}
          >
            {t("common.fold_all_menu")}
          </MenuToggleBtn>
        </Row>
      </AreaHeader>
      <Column $gap="64px">
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(1)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>1. {t("menuCategory.auto_del_period_bet_histoy")}</AreaTitle>
              <HoverCon
                onClick={(e) => { e.stopPropagation(); }}>
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.del_period_bet")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn $pad="6px 12px" onClick={(e) => { e.stopPropagation(); UpdateSiteDelBet(); }}>
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[1] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[1] && (
            <Row>
              <OptionBox $align="center" $flex="1">
                <Column $gap="12px" $width="100%">
                  <Row $gap="6px" $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("menuCategory.del_period")}</TextBox>
                    </Row>
                    <Row $gap="12px" $align="center">
                      <RadioForm name="del_period_bet" options={searchStateEnum} {...useSearch} {...useStated} />
                    </Row>
                  </Row>
                </Column>
              </OptionBox>
              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
              <OptionBox $align="center" $flex="1">
                <Column $gap="12px" $width="100%">
                  <Row $gap="6px" $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("menuCategory.manual_del_bet_histoy")}</TextBox>
                    </Row>
                    <Row $gap="12px" $align="center">
                      <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showHistoyDel()}>
                        {t("gameManagement.betting_del")}
                      </RedBtn>  
                    </Row>
                  </Row>
                </Column>
              </OptionBox>
            </Row>
          )}
        </Column>
      </Column>
    </>
  );
};

export default MenuCategory;
