import React, { useEffect, useState } from "react";

import useFormData from "common/useFormData";
import { AreaTitle } from "style/useTextStyles";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { TextBox } from "style/useTextStyles";
import { LineSection, PositionBox } from "style/useLayoutStyles";
import { InputForm, SelectForm } from "components/common/CommonForm";
import { Column, FilterBox, OptionBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import api from "interceptor/api";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import useTableData from "common/useTableData";
import { FilterTitle } from "style/custom/useCustomText";
import { getFormatDatetime } from "utils/date/DateUtil";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import CalendarFilter from "components/common/CalendarFilter";
import { dbAgentAdmin } from "enum/db/useAgentEnum";
import { dbLogInfoUpdate } from "enum/db/useLogEnum";
import { addAllFromEnum, getLogInfoUpdateMeaningByAgentAdmin } from "utils/common/EnumUtil";
import { useCountPerPage } from "constants/select/useValue";
import useStateData from "common/useStateData";
import NoDataTable from "components/common/NoDataTable";
import { t } from "i18next";

const AgentAdminManage = ({ agentNo, no, parentGetTable, getAgentInfo }) => {
  const useStated = useStateData();

  const [initData, setInitData] = useState(); // 초기 데이터 저장

  const { setLoading } = useCommonState();
  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const [canModify, setCanModify] = useState(false);

  const [firstLv, setFirstLv] = useState();

  useEffect(() => {
    useStated.setErrorData([], true);

    getAgentAdminInfo();
  }, []);

  const showUpdateAgentAdminSuccessAlert = () => {
    openAlert({
      title: t("common.save_msg"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showUpdateAgentAdminFailAlert = () => {
    openAlert({
      title: t("common.save_fail"),
      message: t("common.try_later"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const clickUpdateAgentAdmin = () => {
    if (canModify) {
      const apiUrl = `v1/agent/info/admin-info/${no}`;
      api
        .put(apiUrl, {
          ...useForm.formValue,
        })
        .then((res) => {
          showUpdateAgentAdminSuccessAlert();
          getAgentAdminInfo();
          setCanModify(false);
          getTableItem();
          getAgentInfo();
          parentGetTable();
          useStated.setErrorData([], true);
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            useStated.setErrorData(err.response.data.errors, true);
          }
          showUpdateAgentAdminFailAlert();
        });
    }
  };

  const getAgentAdminInfo = () => {
    setLoading(true);

    const apiUrl = `v1/agent/info/admin-info/${agentNo}`;
    api
      .get(apiUrl, {
        params: {
          no,
        },
      })
      .then((res) => {
        useForm.setFormValue(res.data.content);
        setInitData(res.data.content);
        setLoading(false);

        setFirstLv(res.data.content?.lv);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const useForm = useFormData({ password: "" });

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/agent/info/log-info/${no}`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
          sortNm: "no-desc",
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_updated_info: "",
      search_nickname: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  const clickCancelModify = () => {
    useForm.setFormValue(initData);

    setCanModify(false);
    useStated.setErrorData([], true);
  };

  return (
    <>
      <Column $gap="48px" $width="1500px">
        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.info")}</AreaTitle>
            </Row>
            <Row $gap="6px" $align="center">
              {canModify ? (
                <>
                  <WhiteBtn $pad="6px 12px" onClick={clickCancelModify}>
                    {t("common.modify_cancel")}
                  </WhiteBtn>
                  <MainBtn $pad="6px 12px" onClick={clickUpdateAgentAdmin}>
                    {t("common.go_save")}
                  </MainBtn>
                </>
              ) : (
                <MainBtn $pad="6px 12px" onClick={() => setCanModify(true)}>
                  {t("common.go_modify")}
                </MainBtn>
              )}
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.admin_id")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.id}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.create_at")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.create_at ? getFormatDatetime(useForm.formValue.create_at) : "-"}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="flex-start">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("common.phone")}
              </TextBox>
              {canModify ? (
                <InputForm width="160px" name="phone" disabled={!canModify} {...useForm} {...useStated} />
              ) : (
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.phone}
                </TextBox>
              )}
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
              {t("common.type")}
              </TextBox>

              {canModify ? (
                firstLv === dbAgentAdmin.lv.N.value ? (
                  <SelectForm disabled={!canModify} name="lv" options={dbAgentAdmin.lv} {...useForm} {...useStated} />
                ) : (
                  <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                    {dbAgentAdmin.lv[useForm.formValue.lv]?.label}
                  </TextBox>
                )
              ) : (
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {dbAgentAdmin.lv[useForm.formValue.lv]?.label}
                </TextBox>
              )}
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="flex-start">
              <TextBox $width="120px" $line="var(--l-sub)">
              {t("common.password")}
              </TextBox>

              {canModify ? (
                <Column $gap="4px">
                  <InputForm
                    width="160px"
                    type="password"
                    name="password"
                    disabled={!canModify}
                    placeholder={t("common.new_password")}
                    {...useForm}
                    {...useStated}
                  />
                  <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
                    {t("agent.no_password_msg")}
                  </TextBox>
                </Column>
              ) : (
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  ******
                </TextBox>
              )}
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("common.state")}
              </TextBox>

              {canModify ? (
                <SelectForm disabled={!canModify} name="state" options={dbAgentAdmin.state} {...useForm} {...useStated} />
              ) : (
                <TextBox
                  $font="var(--f-subB)"
                  $line="var(--l-sub)"
                  className={
                    useForm.formValue.state === dbAgentAdmin.state.N.value
                      ? "complete"
                      : useForm.formValue.state === dbAgentAdmin.state.B.value
                      ? "warn"
                      : "normal"
                  }
                >
                  {dbAgentAdmin.state[useForm.formValue.state]?.label}
                </TextBox>
              )}
            </OptionBox>
          </Row>
        </Column>

        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.update_history")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Column $width="100%">
            <FilterBox $pad="12px">
              <Row $gap="12px" $align="center">
                <Column $gap="4px">
                  <FilterTitle>{t("agent.modify_at")}</FilterTitle>
                  <Row $gap="4px">
                    <CalendarFilter {...useSearch} end />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("agent.modity_list")}</FilterTitle>
                  <Row $gap="4px">
                    <SelectForm
                      name="search_updated_type"
                      placeholder={t("common.all")}
                      options={addAllFromEnum(dbLogInfoUpdate.updated_type)}
                      {...useSearch}
                      {...useStated}
                    />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("agent.modity_admin")}</FilterTitle>
                  <Row $gap="4px">
                    <InputForm type="text" name="search_nickname" placeholder={t("common.input")} {...useSearch} {...useStated} />
                  </Row>
                </Column>
              </Row>

              <Row $gap="8px">
                <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                  {t("filter.search")}
                </MainBtn>
                <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                {t("filter.refresh")}
                </WhiteBtn>
              </Row>
            </FilterBox>

            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-count" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
                </Row>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                />
              </Row>
            </Row>

            {useTG.error ? (
              <span>error</span>
            ) : (
              <>
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="60px">{t("common.no")}</TableHeader>
                      <TableHeader $width="200px">{t("agent.modify_at")}</TableHeader>
                      <TableHeader $width="200px">{t("agent.modity_admin")}</TableHeader>
                      <TableHeader $width="100px">{t("agent.modity_list")}</TableHeader>
                      <TableHeader>{t("agent.modify_before")}</TableHeader>
                      <TableHeader>{t("agent.modify_after")}</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>

                          <TableDeps>
                            <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                              {item.update_time ? getFormatDatetime(item.update_time) : "-"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps $tAlign="left">{`${item.nickname}(${item.auth_no})`}</TableDeps>
                          {/* <TableDeps>{item.updated_info}</TableDeps> */}
                          <TableDeps>{dbLogInfoUpdate.updated_type[item.updated_type]?.label || item.updated_type}</TableDeps>
                          <TableDeps> {getLogInfoUpdateMeaningByAgentAdmin(item.updated_type, item.before_update)}</TableDeps>
                          <TableDeps> {getLogInfoUpdateMeaningByAgentAdmin(item.updated_type, item.after_update)}</TableDeps>
                        </TableRow>
                      ))}
                  </tbody>
                </Table>
                {useTG && useTG.data?.length === 0 && <NoDataTable />}
              </>
            )}
            <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
              <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
              </PositionBox>

              <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
                <Row $gap="8px"></Row>
                <Row $gap="8px">
                  <SelectForm
                    {...useTG}
                    name="countPerPage"
                    placeholder={useCountPerPage[0].label}
                    size={"var(--s-table)"}
                    line={"var(--l-table)"}
                    options={useCountPerPage}
                    formValue={useCountPerPage.value}
                    top="top"
                  />
                </Row>
              </Row>
            </Row>
          </Column>
        </Column>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentAdminManage;
