import React from "react";

// 공용
import api from "interceptor/api";

import useTableData from "common/useTableData";

// style import
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { Column } from "style/custom/useCustomLayout";
import { getFormatDateMin } from "utils/date/DateUtil";
import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import { useCommonState } from "contexts/useCommonState";
import NoDataTable from "components/common/NoDataTable";
import { betEnum } from "enum/useCommonEnum";
import { t } from "i18next";
import { differenceBy } from "lodash";

const BettingTransactionFlow = ({ transactionId }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/user/all/bet-win/info/${transactionId}`;
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData({}, getTableItem, useTG);

  return (
    <>
      <Column $width="1600px" $height="600px">
        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader>{t("betting.number")}</TableHeader>
                  <TableHeader>{t("betting.number")}</TableHeader>
                  <TableHeader>{t("common.type")}</TableHeader>
                  <TableHeader>{t("betting.before_amount")}</TableHeader>
                  <TableHeader>{t("betting.after_amount")}</TableHeader>
                  <TableHeader>{t("betting.before_after_amount")}</TableHeader>
                  <TableHeader $width="150px">{t("betting.win_time")}</TableHeader>
                  <TableHeader>{t("betting.action")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const data = JSON.parse(item?.result_json_data);
                    const amount = Number(data.amount)
                    return data ? (
                      <TableRow key={index} className="table-content">
                        <TableDeps $tAlign="right">{item.transaction_id}</TableDeps>
                        <TableDeps $tAlign="right">{data?.referer_id ?? "-"}</TableDeps>
                        <TableDeps>{data?.type?.toUpperCase()}</TableDeps>
                        <TableDeps $tAlign="right">{(data?.before || 0).toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{(amount || 0).toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{((data?.before || 0) + (amount || 0)).toLocaleString()}</TableDeps>
                        <TableDeps>{getFormatDateMin(data?.processed_at) ?? "-"}</TableDeps>
                        <TableDeps>{amount === 0 ?  t("gameManagement.not_win") : betEnum.type[data?.type]?.label}</TableDeps>
                      </TableRow>
                    ) : null;
                  })}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
      </Column>
    </>
  );
};

export default BettingTransactionFlow;
