import React from "react";

// 공용
import api from "interceptor/api";

import useTableData from "common/useTableData";

// style import
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { getFormatDatetime } from "utils/date/DateUtil";
import { FilterTitle } from "style/custom/useCustomText";
import { InputForm, SelectForm } from "components/common/CommonForm";
import useFormData from "common/useFormData";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import CalendarFilter from "components/common/CalendarFilter";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";

import { agentQnaCalendarFilter, useCountPerPage } from "constants/select/useValue";
import { DeleteBtn, ModifyBtn, NormalStatus, WarnStatus } from "components/common/TableStatus";
import { dbAgentBoardQna } from "enum/db/useAgentEnum";
import { addAllFromEnum } from "utils/common/EnumUtil";
import useStateData from "common/useStateData";
import { statesAgentEnum } from "enum/admin/useAdminMenuEnum";
import AgentBoardQnaInfo from "./AgentBoardQnaInfo";
import { useCommonState } from "contexts/useCommonState";
import NoDataTable from "components/common/NoDataTable";
import AgentBoardQnaForm from "./AgentBoardQnaForm";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import AgentNameTab from "components/common/AgentNameTab";
import { t } from "i18next";
import { useAlarmCheck } from "utils/ApiUtil";

const AgentBoardQnaList = ({ agentNo, refresh }) => {
  const useStated = useStateData();
  const { setLoading, isAdmin, isAgent } = useCommonState();

  const { openPopup, closePopup } = usePopup();

  const { openConfirm } = useConfirm();
  const { openAlert } = useAlert();

  const isPopup = !agentNo;

  useAlarmCheck("agent-qna");

  const showQnaInfoPopup = (no) => {
    openPopup({
      title: t("agentQna.qna_answer"),
      content: AgentBoardQnaInfo,
      height: "835px",
      props: {
        no,
        getTableItem,
      },
    });
  };
  const showQnaFormPopup = () => {
    openPopup({
      title: t("agentQna.qna_answer"),
      content: AgentBoardQnaForm,
      height: "835px",
      props: {
        getTableItem,
      },
    });
  };

  const deleteQna = (no) => {
    const apiUrl = `/v1/agent/qna/${no}`;
    api
      .delete(apiUrl)
      .then(() => {
        getTableItem();
        if (refresh) {
          refresh();
        }
        showDeleteQnaSuccessAlert(true);
      })
      .catch((err) => {
        showDeleteQnaFailAlert(false);
      });
  };
  const showDeleteQnaConfirm = (no) => {
    openConfirm({
      title: t("agentQna.delete_confirm"),

      message: t("agentQna.delete_confirm_msg"),
      iconURL: true,
      mainBtn: t("common.delete"),
      Func: () => deleteQna(no),
    });
  };

  const showDeleteQnaSuccessAlert = (state) => {
    openAlert({
      title: t("agentQna.delete_complete"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showDeleteQnaFailAlert = (state) => {
    openAlert({
      title: t("agentQna.delete_fail"),
      message: t("common.try_later"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const getTableItem = () => {
    setLoading(true);
    const apiUrl = !isPopup ? `/v1/agent/qna/agent/${agentNo}` : `/v1/agent/qna`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
          sortNm: "no-desc",
          ...(agentNo ? { agent_no: agentNo } : {}),
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_question_title: "",
      search_state: "",

      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",
      search_agent_type: "",

      search_calendar_category: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  // 조회 버튼

  return (
    <>
      {!isPopup || (
        <AreaHeader>
          <SubText>{t("menu.states_agent")}</SubText>
          <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
          <AreaTitle>{statesAgentEnum.AGENT_QNA.title}</AreaTitle>
        </AreaHeader>
      )}

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="center">
            {isPopup && (
              <>
                <AgentSearchFilter useSearch={useSearch} useStated={useStated} />
              </>
            )}

            <Column $gap="4px">
              <FilterTitle>{t("agentQna.title")}</FilterTitle>
              <Row $gap="4px">
                <InputForm
                  type="text"
                  name="search_question_title"
                  placeholder={t("common.input")}
                  {...useSearch}
                  {...useStated}
                />
              </Row>
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("agentQna.state")}</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="search_state"
                  placeholder={t("common.all")}
                  options={addAllFromEnum(dbAgentBoardQna.state)}
                  {...useSearch}
                  {...useStated}
                />
              </Row>
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("agentQna.search_date")}</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="search_calendar_category"
                  placeholder={t("common.select")}
                  options={agentQnaCalendarFilter}
                  {...useSearch}
                  {...useStated}
                />

                <CalendarFilter {...useSearch} end />
              </Row>
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-count" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>

          <Row $gap="8px">
            {isAgent ? (
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showQnaFormPopup}>
                {t("agentQna.go_qna")}
              </MainBtn>
            ) : null}

            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  {!isPopup || (
                    <>
                      <TableHeader $width="180px">{t("agent.code")}</TableHeader>
                      <TableHeader $width="180px">{t("agent.agent")}</TableHeader>
                    </>
                  )}

                  <TableHeader $width="180px">{t("agentQna.date")}</TableHeader>
                  <TableHeader $width="500px">{t("agentQna.title")}</TableHeader>

                  <TableHeader $width="120px">{t("agentQna.state")}</TableHeader>
                  <TableHeader $width="180px">{t("agentQna.answer_date")}</TableHeader>
                  <TableHeader>{t("agentQna.anser_admin")}</TableHeader>
                  <TableHeader $width="130px">{t("common.manage")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => (
                    <TableRow key={item.no} className="table-content">
                      <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>
                      {!isPopup || (
                        <>
                          <TableDeps $tAlign="left">{item.create_agent_code}</TableDeps>
                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.create_agent_no}
                              code={item.create_agent_code}
                              name={item.create_agent_name}
                              type_name={item.create_agent_type_name}
                              refresh={getTableItem}
                            />
                          </TableDeps>
                        </>
                      )}

                      <TableDeps>
                        <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                          {getFormatDatetime(item.create_at)}
                        </TextBox>
                      </TableDeps>
                      <TableDeps $tAlign="left">
                        {item?.question_title?.length > 40 ? `${item.question_title.slice(0, 40)}...` : `${item.question_title}`}
                      </TableDeps>
                      <TableDeps>
                        <Row $width="100%" $jus="center">
                          {item.state === dbAgentBoardQna.state.P.value ? (
                            <WarnStatus name={dbAgentBoardQna.state[item.state]?.label} />
                          ) : (
                            <NormalStatus name={dbAgentBoardQna.state[item.state]?.label} />
                          )}
                        </Row>
                      </TableDeps>

                      <TableDeps>
                        <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                          {getFormatDatetime(item.answer_at) || "-"}
                        </TextBox>
                      </TableDeps>
                      <TableDeps $tAlign="left">{item.answer_no ? `${item.answer_nickname}(${item.answer_no})` : "-"}</TableDeps>

                      <TableDeps $maxWidth="90px">
                        <Row $width="100%" $jus="center" $gap="6px">
                          <ModifyBtn
                            name={
                              isAdmin
                                ? t("agentQna.answer")
                                : item.answer_contents
                                ? t("agentQna.qna_detail")
                                : t("agentQna.answer_datil")
                            }
                            eventProps={() => showQnaInfoPopup(item.no)}
                          />
                          {isAdmin ? <DeleteBtn eventProps={() => showDeleteQnaConfirm(item.no)} /> : null}
                        </Row>
                      </TableDeps>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <Row $gap="8px">
            {isAgent ? (
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showQnaFormPopup}>
                {t("agentQna.go_qna")}
              </MainBtn>
            ) : null}

            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
              top="top"
            />
          </Row>
        </Row>

        {!isPopup && (
          <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
            <Row $gap="10px" $jus="right" $width="100%">
              <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
                {t("common.cancel")}
              </WhiteBtn>
            </Row>
          </PositionBox>
        )}
      </Column>
    </>
  );
};

export default AgentBoardQnaList;
