import { useEffect } from "react";
import api from "interceptor/api";
import { useCommonState } from "contexts/useCommonState";

const SiteNameSetter = () => {
  const { setSiteName, siteName } = useCommonState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_BASE_URL}/v1/common/site-name`;
    
    api.get(url)
    .then((res) => {
      setSiteName(res.data?.content);
      document.title = `${res.data.content || "game"} (${process.env.REACT_APP_TITLE})`;
    })
    .catch(() => {
      document.title = `game (${process.env.REACT_APP_TITLE})`;
    });
  }, [setSiteName]);

  return null;
};

export default SiteNameSetter;
