import React, { useState } from "react";

import CommonCategoryTab from "components/common/CommonCategoryTab";
import { AGENT_INFO_CATEGORY, AGENT_INFO_CATEGORY_AGENT } from "constants/useCategory";
import AgentInfo from "./AgentInfo";
import AgentAdminInfo from "./AgentAdminInfo";
import AgentBoardQnaList from "../qna/AgentBoardQnaList";
import AgentUserList from "../users/AgentUserList";
import AgentPayment from "../payment/AgentPayment";
import AgentCommission from "../commission/AgentCommission";
import AgentCommissionHistory from "../commission/AgentCommissionHistory";
import { useCommonState } from "contexts/useCommonState";
import { t } from "i18next";

const AgentInfoTab = ({ agentNo, tabNm = 1, refresh }) => {

  const [tabEnum, setTabEnum] = useState(tabNm);
  const { isAdmin, isAgent } = useCommonState();

  const tabMove = (enums) => {
    setTabEnum(enums);
  };
  // 본사의 경우 에이전트 커미션 설정 메뉴 제외
  let agnetInfoCategory = AGENT_INFO_CATEGORY;
  if (agentNo === 1) {
    agnetInfoCategory = AGENT_INFO_CATEGORY.filter((item) => item.title !== t("gameManagement.agent_comp_setting"))
  }

  return (
    <>
      {isAdmin ? (
        <>
          <CommonCategoryTab menus={agnetInfoCategory} tabMove={tabMove} tabEnum={tabEnum} />
          {tabEnum === 1 && <AgentInfo agentNo={agentNo} />}
          {tabEnum === 2 && <AgentAdminInfo agentNo={agentNo} />}
          {/* {tabEnum === 3 && <AgentPayment agentNo={agentNo} refreshTable={refresh} />} */}
          {tabEnum === 4 && <AgentUserList agentNo={agentNo} />}
          {tabEnum === 5 && <AgentBoardQnaList agentNo={agentNo} refresh={refresh} />}
          {tabEnum === 6 && <AgentCommissionHistory agentNo={agentNo} refresh={refresh} />}
          {tabEnum === 7 && agentNo !== 1 && <AgentCommission agentNo={agentNo} refresh={refresh} />}
        </>
      ) : null}

      {isAgent ? (
        <>
          <CommonCategoryTab menus={AGENT_INFO_CATEGORY_AGENT} tabMove={tabMove} tabEnum={tabEnum} />
          {tabEnum === 1 && <AgentInfo agentNo={agentNo} />}
          {tabEnum === 2 && <AgentAdminInfo agentNo={agentNo} />}
          {tabEnum === 3 && <AgentUserList agentNo={agentNo} />}
          {tabEnum < 1 || (tabEnum > 3 && <p>X</p>)}
        </>
      ) : null}
    </>
  );
};

export default AgentInfoTab;
