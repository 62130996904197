import { useState } from "react";

import CommonCategoryTab from "components/common/CommonCategoryTab";
import { USER_INFO_CATEGORY } from "constants/useCategory";
import UserInOutHistory from "./UserInOutHistory";
import UserPointHistory from "./UserPointHistory";
import UserBettingHistory from "./UserBettingHistory";
import UserMessageHistory from "./UserMessageHistory";
import UserQnaHistory from "./UserQnaHistory";
import UserLoginHistory from "./UserLoginHistory";
import UserBlockIP from "./UserBlockIP";
import UserComp from "../comp/UserComp";
import ReferComp from "../comp/ReferComp";

const UserInfoTab = ({ userNo, tabNm = 1 }) => {
  const [tabEnum, setTabEnum] = useState(tabNm);

  const tabMove = (enums) => {
    setTabEnum(enums);
  };

  return (
    <>
      <CommonCategoryTab menus={USER_INFO_CATEGORY} tabMove={tabMove} tabEnum={tabEnum} />
        {tabEnum === 1 && <UserInOutHistory userNo={userNo} />}
        {tabEnum === 2 && <UserPointHistory userNo={userNo} />}
        {tabEnum === 3 && <UserBettingHistory userNo={userNo} />}
        {tabEnum === 4 && <UserMessageHistory userNo={userNo} />}
        {tabEnum === 5 && <UserQnaHistory userNo={userNo} />}
        {tabEnum === 6 && <UserLoginHistory userNo={userNo} />}
        {tabEnum === 7 && <UserBlockIP userNo={userNo} />}
        {tabEnum === 8 && <UserComp userNo={userNo} />}
        {tabEnum === 9 && <ReferComp userNo={userNo} />}
    </>
  );
};

export default UserInfoTab;
