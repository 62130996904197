import { useEffect } from "react";

import useFormData from "common/useFormData";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import api from "interceptor/api";

import useStateData from "common/useStateData";
import BankSelectForm from "components/common/BankSelectForm";
import { InputForm, SelectForm, TextareaForm } from "components/common/CommonForm";
import UserLevelForm from "components/common/UserLevelForm";
import { formNewUser } from "enum/user/useUserFromEnum";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";
import { t } from "i18next";

const AllUserForm = ({ getTableItem }) => {
  const useStated = useStateData();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();

  const showFormAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("user.user_regis_complete") : t("user.user_regis_fail"),
      message: isSuccess ? t("user.user_regis_complete_msg") : t("user.user_regis_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  const useForm = useFormData({
    join_state: "T",
    state1: "T",
    id: "",
    nickname: "",
    lv: 0,
    password: "",
    re_password: "",

    phone_number: "",
    email: "",

    account_bank: 1,
    account_name: "",
    account_no: "",
    account_out_password: "",

    join_code: "",
    memo: "",
  });

  useEffect(() => {
    if (useForm.formValue.join_state === formNewUser.join_state.T.value) {
      useForm.setFormValue({ ...useForm.formValue, state1: "T" });
    } else if (useForm.formValue.join_state === formNewUser.join_state.S.value) {
      useForm.setFormValue({ ...useForm.formValue, state1: "S" });
    }
  }, [useForm.formValue.join_state]);

  /**
   * 중복체크
   * @param {String} key
   */
  const checkDuplicated = (key) => {
    const apiUrl = `/v1/user/all/duplicate/${key}`;

    api
      .get(apiUrl, {
        params: {
          ...useForm.formValue,
        },
      })
      .then((res) => {
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });
  };

  const checkJoinCode = () => {
    const apiUrl = `/v1/user/all/join-code`;

    api
      .get(apiUrl, {
        params: {
          ...useForm.formValue,
        },
      })
      .then((res) => {
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });
  };

  /**
   * 신규회원 등록
   */
  const showInserUserConfirm = () => {
    let isTestUser = useForm.formValue.join_state === formNewUser.join_state.T.value;
    openConfirm({
      title: t("user.user_regis"),
      message: isTestUser ? t("user.test_user_regis_confirm") : t("user.normal_user_regis_confirm"),
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => insertUser(),
    });
  };
  const insertUser = () => {
    const apiUrl = "/v1/user/all/user";
    api
      .post(apiUrl, {
        ...useForm.formValue,
      })
      .then(() => {
        showFormAlert(true);
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showFormAlert(false);
      });
  };

  return (
    <>
      <Column className="area-box" $gap="20px" $width="800px">
        <Row $gap="48px">
          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.join_state")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <SelectForm name="join_state" options={formNewUser.join_state} {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.user_state1")} {useForm.formValue.join_state === formNewUser.join_state.S.value && <TextBox $color="red">*</TextBox>}
            </TextBox>
            {useForm.formValue.join_state === formNewUser.join_state.T.value ? (
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {formNewUser.join_state[useForm.formValue.state1]?.label}
              </TextBox>
            ) : useForm.formValue.join_state === formNewUser.join_state.S.value ? (
              <SelectForm
                name="state1"
                options={formNewUser.state1}
                {...useForm}
                {...useStated}
              />

            ) : (
              <></>
            )}
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("common.id")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm maxWidth="100%" type="text" name="id" {...useForm} {...useStated} />
            <WhiteBtn
              $minWidth="80px"
              $height="30px"
              $size="var(--s-table)"
              $line="var(--l-table)"
              onClick={() => checkDuplicated("id")}
            >
              {t("common.check_duplicate")}
            </WhiteBtn>
          </FormBox>
          <UserLevelForm useForm={useForm} useStated={useStated} width="120px" isRequired={true} />
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("common.password")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm maxWidth="100%" type="password" name="password" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("common.re_password")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm maxWidth="100%" type="password" name="re_password" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1"></FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("common.phone")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm
              maxWidth="100%"
              type="text"
              name="phone_number"
              placeholder="+00 00-0000-0000"
              maskType="phone"
              {...useForm}
              {...useStated}
            />
          </FormBox>

          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.email")}
            </TextBox>
            <InputForm maxWidth="100%" type="text" name="email" {...useForm} {...useStated} />
          </FormBox>

        </Row>

        <Row $gap="48px">
          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.nickname")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm maxWidth="100%" type="text" name="nickname" {...useForm} {...useStated} />
            <WhiteBtn
              $minWidth="80px"
              $height="30px"
              $size="var(--s-table)"
              $line="var(--l-table)"
              onClick={() => checkDuplicated("nickname")}
            >
              {t("common.check_duplicate")}
            </WhiteBtn>
          </FormBox>

          <FormBox $flex="1">
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1"></FormBox>
        </Row>

        <Row $gap="48px">
          <BankSelectForm useForm={useForm} useStated={useStated} width="120px" isRequired={true} />

          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.account_name")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm maxWidth="100%" type="text" name="account_name" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.account_number")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm maxWidth="100%" type="text" name="account_no" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.withdrawal_password")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm maxWidth="100%" type="password" name="account_out_password" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.join_code")} <TextBox $color="red">*</TextBox>
            </TextBox>
            <InputForm maxWidth="100%" type="text" name="join_code" {...useForm} {...useStated} />
            <WhiteBtn
              $minWidth="80px"
              $height="30px"
              $size="var(--s-table)"
              $line="var(--l-table)"
              onClick={() => checkJoinCode()}
            >
              {t("user.code_check")}
            </WhiteBtn>
          </FormBox>

          <FormBox $flex="1"></FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1">
            <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
              {t("user.admin_memo")}
            </TextBox>
            <TextareaForm type="text" name="memo" placeholder={t("user.admin_memo_msg")} {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={showInserUserConfirm}>
              {t("common.save")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AllUserForm;
