import UserInfo from "components/admin/user/info/UserInfo";
import { useCommonState } from "contexts/useCommonState";
import { usePopup } from "contexts/useWindow";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { HoverTableText } from "style/custom/useCustomText";
import { InfoTabBox, InfoTabList } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

/**
 *
 * @param {*} no 유저 번호
 * @param {*} nickname 유저 닉네임
 * @param {*} refresh refresh 할 데이터
 * @param {*} tabList tabList tab 보여줄 리스트
 *
 *
 * @returns
 */
const UserNameTab = ({ no, nickname, refresh, tabList, popupRef }) => {
  const [openInfoTab, setOpenInfoTab] = useState(false);
  const tabBoxRef = useRef(null);
  const infoTabRef = useRef();

  const { isAdmin } = useCommonState();
  const { openPopup } = usePopup();

  const infoTabToggle = () => {
    setOpenInfoTab((prevState) => !prevState);
  };

  useEffect(() => {
    if (openInfoTab && tabBoxRef.current && infoTabRef.current) {
      const tabBoxRect = tabBoxRef.current.getBoundingClientRect();
      const infoTabRect = infoTabRef.current.getBoundingClientRect();

      const spaceBelow = popupRef
        ? popupRef.current.getBoundingClientRect().bottom - tabBoxRect.bottom
        : window.innerHeight - tabBoxRect.bottom;

      const spaceAbove = tabBoxRect.bottom;

      if (spaceBelow < infoTabRect.height && spaceAbove > infoTabRect.height) {
        infoTabRef.current.style.top = `auto`;
        infoTabRef.current.style.bottom = `100%`;
      } else {
        infoTabRef.current.style.top = "100%";
      }
    }
  }, [openInfoTab]);

  const showUserInfoPopup = (userNo, nickName, tabNm = 1) => {
    openPopup({
      title: nickName ? `${t("user.tab_user_info")} (${nickName})` : t("user.tab_user_info"),
      content: UserInfo,
      props: {
        userNo,
        tabNm,
        getTableItem: refresh,
      },
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tabBoxRef.current && !tabBoxRef.current.contains(event.target)) {
        setOpenInfoTab(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const TabList = () => {
    if (tabList) {
      return <>{tabList}</>;
    } else {
      if (isAdmin) {
        return (
          <>
            <InfoTabList onClick={() => showUserInfoPopup(no, nickname, 1)}>{t("user.tab_user_info")}</InfoTabList>
            <InfoTabList>{t("user.send_message")}</InfoTabList>
            <InfoTabList onClick={() => showUserInfoPopup(no, nickname, 5)}>{t("user.qna_history")}</InfoTabList>
          </>
        );
      } else {
        return (
          <>
            <InfoTabList onClick={() => showUserInfoPopup(no, nickname)}>회원정보</InfoTabList>
          </>
        );
      }
    }
  };

  return no ? (
    <HoverTableText onClick={infoTabToggle} ref={tabBoxRef}>
      {`${nickname || "-"} (${no || "-"})`}
      {openInfoTab && (
        <InfoTabBox ref={infoTabRef}>
          <TabList />
        </InfoTabBox>
      )}
    </HoverTableText>
  ) : (
    <TextBox>-</TextBox>
  );
};

export default UserNameTab;
