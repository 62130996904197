import api from "interceptor/api";
import React, { useEffect, useState } from "react";
import { Column, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { SelectForm } from "./CommonForm";
import { t } from "i18next";

const GameSearchFilter = ({ useSearch, useStated, gameType = null }) => {

  const type = [
    {label: t("common.all"), value: ""},
    { label: t("gameStatistics.casino"), value: "C" },
    { label: t("gameStatistics.slot"), value: "S" },
    { label: t("gameStatistics.mini_game"), value: "M" },
    { label: t("gameStatistics.sports"), value: "P" }
  ];
  const [vendor, setVendor] = useState([]);
  const [allVendor, setAllVendor] = useState([]);
  const [game, setGame] = useState([]);
  const [allGame, setAllGame] = useState([]);
  
  const selectType = (type) => {
    if (type) {
      setVendor(
        allVendor.filter((data) => {
          return data.type === type
        })
      )
    } else {
      setVendor(allVendor)
    }
  }

  const selectVendor = (vendor) => {
    if (vendor) {
      setGame(
        allGame.filter((data) => {
          return data.vendor === vendor
        })
      )
    } else {
      setGame(allGame)
    }
  }

  // 게임사 조회
  const getVendor = () => {
    api.get("/v1/game/vendor", {
      params: {
        game_type: gameType
      }
    })
    .then((res) => {
      const agentName = res.data.reduce((acc, item) => {
        acc.push({
          label: item.name,
          value: item.name,
          type: item.type,
        });
        return acc;
      }, [{
        label: t("common.all"),
        value: ""
      }]);

      setVendor(agentName)      
      setAllVendor(agentName)      
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        useStated.setErrorData(err.response.data.errors, true);
      }
    });
  };

  // 게임 조회
  const getGame = () => {
    api.get("/v1/game/game-list", {
        params: {
          game_type: gameType
        }
      })
      .then((res) => {
      const agentName = res.data.reduce((acc, item) => {
        acc.push({
          label: item.title,
          value: item.title,
          vendor: item.vendor
        });
        return acc;
      }, [{
        label: t("common.all"),
        value: ""
      }]);

      setGame(agentName)      
      setAllGame(agentName)      
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        useStated.setErrorData(err.response.data.errors, true);
      }
    });
  };
  
  useEffect(() => {
    getVendor();
    getGame();
  }, []);
  
  return (
    <Row $mar="0 24px 0 0" $gap="12px">
        <Row $gap="4px">
          { 
            !gameType && <Column $gap="4px">
              <FilterTitle>{t("menuCategory.type")}</FilterTitle>
              <SelectForm
                name="search_game_type"
                placeholder={t("menuCategory.type")}
                options={type}
                {...useSearch}
                {...useStated}
                width={'150px'}
                onChangeEx={(e, v) => {selectType(v)}}
              />
            </Column>
          }
          <Column $gap="4px">
            <FilterTitle>{t("gameManagement.game_vendor")}</FilterTitle>
            <SelectForm
              name="search_vendor"
              placeholder={t("gameManagement.game_vendor")}
              options={vendor}
              {...useSearch}
              {...useStated}
              width={'180px'}
              onChangeEx={(e, v) => {selectVendor(v)}}
            />
          </Column>
          <Column $gap="4px">
            <FilterTitle>{t("gameManagement.game_name")}</FilterTitle>
            <SelectForm
              name="search_gmae_name"
              placeholder={t("gameManagement.game_name")}
              options={game}
              {...useSearch}
              {...useStated}
              width={'350px'}
            />
          </Column>
        </Row>
    </Row>
  );
};

export default GameSearchFilter;
